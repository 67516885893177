import React, { useEffect } from "react";
import Imgix from "react-imgix";
import { navigate } from "gatsby";
import cn from "classnames";
import { useSelector } from "react-redux";
import styles from "./Onboarding.module.scss";
import { useAppDispatch } from "../../store";
import { selectUserInfo } from "../../store/user/selectors";
import { User } from "../../types/User";
import { getUserInfo } from "../../store/user/actions";
import RoundedButton from "../Buttons/RoundedButton";

const ThumbsUpImage =
  "https://imgix.cosmicjs.com/2702a190-febd-11eb-aaf9-c7d10ae67fc2-ThumbsUpBidFoot.png";

const Congrats = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state) => selectUserInfo(state)) as User;

  useEffect(() => {
    if (!user) {
      dispatch(getUserInfo());
    }
  }, []);

  const onNext = (path) => {
    navigate(path);
  };

  return (
    <div className={cn([styles.congratsContainer, styles.defaultContainer])}>
      <div className={styles.congratsImageContainer}>
        <Imgix
          src={`${ThumbsUpImage}?auto=format`}
          height={281}
          width={205}
          htmlAttributes={{
            alt: "Congrats",
            src: `${ThumbsUpImage}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
      </div>
      <h3>Congrats {user && user.data.first_name}</h3>
      <h2>Your Bidddy Profile is Ready.</h2>
      <p>
        It&apos;s time for some fun! Click <strong>Get Started</strong> to start
        building auctions on your personal dashboard. When you are ready to post
        a live auction, we&apos;ll help you connect to your Instagram Account.
      </p>
      <RoundedButton onClick={() => onNext("/dashboard")} id="submit">
        Get Started
      </RoundedButton>
      <button
        type="button"
        onClick={() => onNext("/dashboard/getting-started/how-it-works/")}
        className={styles.howItWorksButton}
      >
        How It Works
      </button>
    </div>
  );
};

export default Congrats;

import React from "react";
import { AuthProvider } from "../../../components/Auth/auth";
import SimpleSpring from "../../../components/Transitions/transitions";
import styles from "../../../styles/Signin.module.scss";
import Congrats from "../../../components/Onboarding/Congrats";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import LoginLayout from "../../../components/LoginLayout";

const CongratsPage = ({ location }) => (
  <SimpleSpring location={location}>
    <SignUpHeader />
    <AuthProvider>
      <LoginLayout hideHeader title="Bidddy - Congrats Your Profile is ready">
        <div className={styles.loginContainer}>
          <Congrats />
        </div>
      </LoginLayout>
    </AuthProvider>
  </SimpleSpring>
);

export default CongratsPage;
